import logo from './logo.svg';
import './App.css';
import background from "./background.jpeg";

function Header(props) {
  return (
    <header className="White">
      <h1>Wasai Flor</h1>
    </header>
  );
}

function Main(props) {
  return (
    <section>
      <img
        src={background}
        className="Scale"
        alt="river"
        
      />
    </section>
  );
}

function Footer(props) {
  return (
    <footer className="White">
      <p>Coming Soon</p>
    </footer>
  );
}


function App() {
  return (
    <div className="App" align="center">
      <Header name="Wasai" />
      <Main />
      <Footer year={new Date().getFullYear()} />
    </div>
  );
}

export default App;
